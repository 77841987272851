// routes
import { useEffect } from 'react';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import socket from './utils/socket';
import useAuth from './hooks/useAuth';

// ----------------------------------------------------------------------

export default function App() {

  const {user}=useAuth()

  useEffect(() => {
    if (user?._id) {
      socket.emit('login', user?._id);
    }
    return () => {
      // socket.emit('logout');
      console.log('disconnect Socket');
      
      // socket.disconnect();
    };
  }, [user]);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
